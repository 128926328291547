import React, { useRef } from 'react'
import { Button, FormGroup, Input } from '@te-digi/styleguide'

const FocusCustomExample = () => {
  const inputRef = useRef<HTMLDivElement>(null)

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <>
      <FormGroup ref={inputRef}>
        <Input label="Label" />
      </FormGroup>
      <Button onClick={() => onButtonClick()}>Fokusoi</Button>
    </>
  )
}

export { FocusCustomExample }
