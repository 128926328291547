import React, { useState } from 'react'

import { Button, Focus, FormGroup, Input, PlusIcon } from '@te-digi/styleguide'

import lorem from '../../lorem'

const FocusInputExample = () => {
  const [shouldFocus, setShouldFocus] = useState(false)
  const [inputs, setInputs] = useState([
    { id: 0, label: lorem({ words: 4 }), value: '' },
    { id: 1, label: lorem({ words: 4 }), value: '' }
  ])

  const onInputChange = (value: string, id: number) => {
    const tempInputs = inputs.map(input =>
      input.id === id ? { ...input, value } : input
    )
    setInputs(tempInputs)
  }

  const onAddClick = () => {
    const tempInputs = [
      ...inputs,
      { id: inputs.length, label: lorem({ words: 4 }), value: '' }
    ]
    setInputs(tempInputs)
    setShouldFocus(true)
  }

  return (
    <>
      {inputs.map((input, index) => {
        const isLast = inputs.length === index + 1
        const focusEnabled = isLast && shouldFocus

        return (
          <FormGroup key={input.id}>
            <Focus enabled={focusEnabled}>
              <Input
                label={input.label}
                onBlur={focusEnabled ? () => setShouldFocus(false) : undefined}
                onChange={event => onInputChange(event.target.value, input.id)}
                value={input.value}
              />
            </Focus>
          </FormGroup>
        )
      })}
      <Button
        iconLeft={<PlusIcon />}
        onClick={onAddClick}
      >
        Lisää uusi asia
      </Button>
    </>
  )
}

export { FocusInputExample }
