import React, { useState } from 'react'

import {
  addNotification,
  Button,
  Focus,
  FormGroup,
  Inline,
  Input,
  PlusIcon,
  Spacer,
  TrashIcon
} from '@te-digi/styleguide'

import lorem from '../../lorem'

const FocusFormGroupExample = () => {
  const [shouldFocus, setShouldFocus] = useState(false)
  const [focusId, setFocusId] = useState(-1)

  const [inputs, setInputs] = useState([
    { id: 0, label: 'Ykkönen', value: '' },
    { id: 1, label: 'Kakkonen', value: '' },
    { id: 2, label: 'Kolmonen', value: '' },
    { id: 3, label: 'Nelonen', value: '' }
  ])

  const onInputChange = (value: string, id: number) => {
    const tempInputs = inputs.map(input =>
      input.id === id ? { ...input, value } : input
    )
    setInputs(tempInputs)
  }

  const onAddClick = () => {
    const tempInputs = [
      ...inputs,
      { id: inputs.length, label: lorem({ words: 2 }), value: '' }
    ]
    setInputs(tempInputs)
    setShouldFocus(true)
    setFocusId(inputs.length)
    addNotification('Uusi rivi lisätty.', { hidden: true })
  }

  const onRemovelick = (index: number) => {
    const removedElemText = inputs[index]?.label
    const rownumber: number = index + 1
    inputs.splice(index, 1)
    const copy = [...inputs]
    setInputs(copy)
    setShouldFocus(true)
    if (index === inputs.length) {
      setFocusId(-1)
    } else {
      setFocusId(index)
    }

    addNotification(
      'Rivi ' + rownumber + ' ' + removedElemText + ' poistettu.',
      { hidden: true }
    )
  }

  return (
    <>
      {inputs.map((input, index) => {
        const isFocusableElement = input.id === focusId
        const focusEnabled = isFocusableElement && shouldFocus

        return (
          <Focus
            enabled={focusEnabled}
            key={`${input.id}_${input.label}`}
          >
            <FormGroup>
              <Inline
                alignItems="end"
                gap="md"
              >
                <Input
                  label={input.label}
                  onChange={event =>
                    onInputChange(event.target.value, input.id)
                  }
                  value={input.value}
                />
                <Spacer paddingBottom="xs">
                  <Button
                    color="danger"
                    iconLeft={<TrashIcon size="xl" />}
                    onClick={() => onRemovelick(index)}
                    variant="plain"
                    ariaLabel={`Poista rivi ${index + 1}`}
                  >
                    Poista rivi
                  </Button>
                </Spacer>
              </Inline>
            </FormGroup>
          </Focus>
        )
      })}
      <Focus enabled={shouldFocus && focusId === -1}>
        <Button
          iconLeft={<PlusIcon />}
          onClick={onAddClick}
        >
          Lisää uusi rivi
        </Button>
      </Focus>
    </>
  )
}

export { FocusFormGroupExample }
