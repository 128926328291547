import React from 'react'

import {
  Focus,
  List,
  ListItem,
  Notifications,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

import { FocusCollapsiblesExample } from '../../examples/react/FocusCollapsiblesExample'
import { FocusFormGroupExample } from '../../examples/react/FocusFormGroupExample'
import { HeadingFocusExample } from '../../examples/react/HeadingFocusExample'
import { FocusInputExample } from '../../examples/react/FocusInputExample'
import { FocusCustomExample } from '../../examples/react/FocusCustomExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Focus"
    components={[{ component: Focus }]}
  >
    <Section>
      <Paragraph>
        <Code>Focus</Code>-komponentti asettaa fokuksen ensimmäiseen sen sisällä
        olevaan komponenttiin näkymän renderöintivaiheessa.
      </Paragraph>
    </Section>
    <Section title="Fokus komponentin lisäyksessä">
      <Playground example={FocusCollapsiblesExample} />
      <Playground
        example={FocusInputExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Fokus FormGroupiin">
      <Paragraph>
        Esimerkissä rivin poistosta annetaan käyttäjälle piilotettu ilmoitus ja
        siirretään fokus poistetun rivin alapuolella olevaan FormGroupiin.
        Fokusta ei kannata siirtää input-kenttään, vaan FormGroupiin siksi, että
        saadaan ruudunlukija lukemaan myös piilotettu ilmoitus.
      </Paragraph>
      <Playground
        example={FocusFormGroupExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Fokus otsikkoon">
      <Paragraph>
        Kun käyttäjä navigoi lomakesivulta toiselle, fokus siirretään sivun
        otsikkoon <Code>Focus</Code>-komponentilla. Huomaa, että otsikkoon ei
        ole tarkoitus siirtyä näppäimistöllä navigoimalla vaan fokus on
        otsikossa sivulle siirryttäessä. Tämän vuoksi käytetään{' '}
        <Code>tabindex=-1</Code>.
      </Paragraph>
      <Paragraph>
        Fokus siirretään otsikkoon helpottamaan näppäimistö- ja
        ruudunlukijakäyttäjää jatkamaan moniosaisen lomakkeen täyttämistä.
        Otsikkoon siirtymistä <Code>tabindex=-1</Code>:llä voidaan ajatella
        hyppylinkkinä.
      </Paragraph>
      <Paragraph>Katso myös</Paragraph>
      <List variant="unordered">
        <ListItem>
          <Link to="/typografia/heading/#otsikon-fokusointi">
            Heading: Otsikon fokusointi
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/patternit/monisivuisen-lomakkeen-navigaatio">
            Monisivuisen lomakkeen navigaatio
          </Link>
        </ListItem>
      </List>
      <Playground example={HeadingFocusExample} />
    </Section>
    <Section title="Custom fokus">
      <Paragraph>
        Esimerkki käyttötapauksesta, jossa ei voida käyttää <Code>Focus</Code>
        -komponenttia fokusointiin.
      </Paragraph>

      <Playground
        example={FocusCustomExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Notifications />
  </Content>
)

export default Page
